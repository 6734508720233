exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-category-sights-map-page-template-js": () => import("./../../../src/templates/category-sights-map-page-template.js" /* webpackChunkName: "component---src-templates-category-sights-map-page-template-js" */),
  "component---src-templates-sight-images-page-template-js": () => import("./../../../src/templates/sight-images-page-template.js" /* webpackChunkName: "component---src-templates-sight-images-page-template-js" */)
}

